// React libraries
import { useState, useEffect } from 'react';

// User defined
import Like from './Like';
import messageService from '../../services/messages';
import { classNames } from '../../utils/classNames';

// Styles
import './interactions.css';

const useStickyState = (defaultValue, key) => {
    // Create our custom hook with init value equal to default or what is present in localstorage
    const [value, setValue] = useState(() => {
        const stickyValue = localStorage.getItem(key);

        // if sticky value present in local storage, return the json parsed version of it, else return
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });

    // After every render, if key or value changed, set key:value pair in localstorage
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

const Interactions = ({ message }) => {
    const STATES = {
        LIKED: 'liked',
        DISLIKED: 'disliked',
        NOINTERACTION: 'nointeraction',
    };

    const [totalLikes, setTotalLikes] = useState(message['likes']);
    const [totalDisLikes, setTotalDisLikes] = useState(message['dislikes']);
    const [status, setStatus] = useStickyState(
        STATES.NOINTERACTION,
        `message_${message['id'].toString()}_status`,
    );

    const [clickedLike, setLikeState] = useState(false);
    const [clickedDislike, setDislikeState] = useState(false);

    useEffect(() => {
        setTotalDisLikes(message['dislikes']);
        setTotalLikes(message['likes']);
    }, [message]);

    async function likeMessage() {
        const res = await messageService.like(message['id'], clickedLike);
        setTotalLikes(res['data']['likes']);
        setLikeState(!clickedLike);
    }

    async function dislikeMessage() {
        const res = await messageService.dislike(message['id'], clickedDislike);
        setTotalDisLikes(res['data']['dislikes']);
        setDislikeState(!clickedDislike);
    }

    const handleLike = () => {
        if (clickedLike === false) {
            if (clickedDislike === true) {
                // unlike the dislike button
                dislikeMessage().then(() => {
                    likeMessage().then(() => {
                        setStatus(STATES.LIKED);
                    });
                });
            } else {
                // like the message
                likeMessage().then(() => {
                    setStatus(STATES.LIKED);
                });
            }
        } else {
            // unlike the message
            likeMessage().then(() => {
                setStatus(STATES.NOINTERACTION);
            });
        }
    };

    const handleDisLike = () => {
        if (clickedDislike === false) {
            if (clickedLike === true) {
                // unlike the like button
                likeMessage().then(() => {
                    dislikeMessage().then(() => {
                        setStatus(STATES.DISLIKED);
                    });
                });
            } else {
                // dislike the message
                dislikeMessage().then(() => {
                    setStatus(STATES.DISLIKED);
                });
            }
        } else {
            // unlike the message
            dislikeMessage().then(() => {
                setStatus(STATES.NOINTERACTION);
            });
        }
    };

    return (
        <div className="interactions">
            <Like
                likesHandler={handleLike}
                className={classNames(
                    status !== STATES.LIKED
                        ? 'like__block-thumbsup'
                        : 'like__block-thumbsup-green',
                )}
                totalLikes={totalLikes}
            />

            <Like
                likesHandler={handleDisLike}
                className={classNames(
                    status !== STATES.DISLIKED
                        ? 'like__block-thumbsdown'
                        : 'like__block-thumbsdown-red',
                )}
                totalLikes={totalDisLikes}
            />
        </div>
    );
};

export default Interactions;
