// Returns array with 2 elements:
// [Date (str), Time (str)]
// Example: ["Nov 12", "15:55"]
export function formatDate(dateString) {
    const dateTime = new Date(dateString);

    const minutesInteger = dateTime.getMinutes();
    let minutesString;
    if (minutesInteger < 10) {
        minutesString = `0${minutesInteger}`;
    } else {
        minutesString = `${minutesInteger}`;
    }

    return [
        `${dateTime.toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
        })}`,
        `${dateTime.getHours()}:${minutesString}`,
    ];
}
