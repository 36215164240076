const ParsedMessage = ({ message }) => {
    const regexSqr = new RegExp('\\[(.*?)\\]', 'gm');
    const regexDollar = /\$[^$]*\$/g;
    const regexParenthesis = /\(([^)]+)\)/gm;
    const messageSplitted = message.message.split('^');
    let newMessage = [];

    for (let i = 0; i < messageSplitted.length; i++) {
        let element = messageSplitted[i];

        if (element.match(regexDollar)) {
            if (element.match(regexSqr)) {
                const link = element.match(regexParenthesis)[0].slice(1, -1);
                element =
                    '<a class="link" href="' +
                    link +
                    '" target="_blank">' +
                    element.match(regexSqr)[0].slice(1, -1) +
                    '</a>';
            }
        } else {
            element = '<a>' + element + '</a>';
        }
        newMessage.push(element);
    }

    return (
        <div>
            {newMessage.map((item) => {
                return (
                    <span
                        key={item}
                        dangerouslySetInnerHTML={{ __html: item }}
                    />
                );
            })}
        </div>
    );
};

export default ParsedMessage;
