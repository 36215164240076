import WhatsAppSVG from './components/Button/SVGs/WhatsAppSVG';
import MessengerSVG from './components/Button/SVGs/MessengerSVG';
import ImessageSVG from './components/Button/SVGs/ImessageSVG';
import TwitterSVG from './components/Button/SVGs/TwitterSVG';
import InstagramSVG from './components/Button/SVGs/InstagramSVG';
import SnapchatSVG from './components/Button/SVGs/SnapchatSVG';
import MailSVG from './components/Button/SVGs/MailSVG';
import TiktokSVG from './components/Button/SVGs/TiktokSVG';
import UpdateSVG from './components/Button/SVGs/UpdateSVG';
import LivecrowdSVG from './components/Button/SVGs/LivecrowdSVG';
import GoogleMapsSVG from './components/Button/SVGs/GoogleMapsSVG'

export const buttons = Object.freeze([
    {
        id: 12,
        title: 'S.O.S',
        titleShadow: '',
        className: 'btn--sos',
        textColorClass: 'text--white',
        href: '#',
        contact: '',
        svgComponent: WhatsAppSVG,
        show: false,
        active: false,
    },
    {
        id: 1,
        title: 'WhatsApp updates',
        titleShadow: '',
        className: 'btn--updates',
        textColorClass: 'text--white',
        href: process.env.REACT_APP_UPDATE,
        contact: '+31638882552',
        svgComponent: UpdateSVG,
        show: false,
        active: false,
    },
    {
        id: 2,
        title: 'iMessage',
        titleShadow: '',
        className: 'btn--imessage',
        textColorClass: 'text--black',
        href: process.env.REACT_APP_IMESSAGE,
        contact: 'Livecrowd',
        svgComponent: ImessageSVG,
        show: true,
        active: true,
    },
    {
        id: 3,
        title: 'WhatsApp',
        titleShadow: '',
        className: 'btn--whatsapp',
        textColorClass: 'text--white',
        href: process.env.REACT_APP_WHATSAPP,
        contact: '+31638882552',
        svgComponent: WhatsAppSVG,
        show: true,
        active: true,
    },
    {
        id: 4,
        title: 'Live Chat',
        titleShadow: '',
        className: 'btn--livechat',
        textColorClass: 'text--white',
        href: '#',
        contact: 'livecrowdnl',
        svgComponent: LivecrowdSVG,
        show: true,
        active: true,
    },
    {
        id: 5,
        title: 'Messenger',
        titleShadow: '',
        className: 'btn--messenger',
        textColorClass: 'text--white',
        href: process.env.REACT_APP_MESSANGER,
        contact: '@livecrowdnl',
        svgComponent: MessengerSVG,
        show: true,
        active: true,
    },
    {
        id: 6,
        title: 'X',
        titleShadow: '',
        className: 'btn--twitter',
        textColorClass: 'text--white',
        href: process.env.REACT_APP_TWITTER,
        contact: '@livecrowdnl',
        svgComponent: TwitterSVG,
        show: true,
        active: true,
    },
    {
        id: 7,
        title: 'Instagram',
        titleShadow: '',
        className: 'btn--instagram',
        textColorClass: 'text--white',
        href: process.env.REACT_APP_INSTAGRAM,
        contact: '@livecrowdnl',
        svgComponent: InstagramSVG,
        show: true,
        active: true,
    },
    {
        id: 8,
        title: 'Snapchat',
        titleShadow: 'text-shadow',
        className: 'btn--snapchat',
        textColorClass: 'text--grey',
        href: '#',
        contact: '@livecrowdnl',
        svgComponent: SnapchatSVG,
        show: false,
        active: false,
    },
    {
        id: 9,
        title: 'E-mail',
        titleShadow: '',
        className: 'btn--mail',
        textColorClass: 'text--white',
        href: process.env.REACT_APP_MAIL,
        contact: 'support@livecrowd.com',
        svgComponent: MailSVG,
        show: false,
        active: false,
    },
    {
        id: 10,
        title: 'TikTok',
        titleShadow: '',
        className: 'btn--tiktok',
        textColorClass: 'text--black',
        href: '#',
        contact: '@livecrowdnl',
        svgComponent: TiktokSVG,
        show: false,
        active: false,
    },
    {
        id: 11,
        title: 'Google Maps',
        titleShadow: '',
        className: 'btn--maps',
        textColorClass: 'text--black',
        href: 'https://goo.gl/maps/3Dk15xKWNNbGZWww9',
        contact: '',
        svgComponent: GoogleMapsSVG,
        show: false,
        active: true,
    },
]);
