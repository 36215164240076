const MessengerSVG = () => {
    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 7.76C0 3.301 3.493 0 8 0C12.507 0 16 3.301 16 7.76C16
					12.219 12.507 15.52 8 15.52C7.19 15.52 6.414 15.413 5.684
					15.213C5.54245 15.1736 5.39165 15.1842 5.257 15.243L3.669
					15.945C3.57309 15.9873 3.46826 16.0054 3.36372 15.9977C3.25918
					15.99 3.15812 15.9568 3.06944 15.9009C2.98076 15.845 2.90717
					15.7682 2.85513 15.6772C2.80309 15.5862 2.7742 15.4838 2.771
					15.379L2.727 13.956C2.72396 13.8694 2.70333 13.7843 2.66637
					13.7059C2.62941 13.6275 2.57689 13.5575 2.512 13.5C0.956 12.108 0
					10.092 0 7.76ZM5.546 6.301L3.196 10.029C2.971 10.387 3.41 10.79
					3.747 10.535L6.272 8.619C6.35505 8.55592 6.45638 8.52161 6.56067
					8.52125C6.66495 8.52089 6.76652 8.5545 6.85 8.617L8.719
					10.019C8.85161 10.1184 9.00338 10.1893 9.16475 10.2272C9.32612
					10.2651 9.49359 10.2691 9.6566 10.2391C9.8196 10.209 9.97461
					10.1455 10.1119 10.0525C10.2491 9.95958 10.3656 9.83921 10.454
					9.699L12.804 5.971C13.03 5.613 12.59 5.21 12.253 5.465L9.728
					7.381C9.64495 7.44408 9.54362 7.47839 9.43933 7.47875C9.33505
					7.47911 9.23348 7.4455 9.15 7.383L7.281 5.98C7.14839 5.88056
					6.99662 5.80966 6.83525 5.77179C6.67388 5.73391 6.50641 5.72988
					6.3434 5.75995C6.1804 5.79001 6.02539 5.85352 5.88815
					5.94647C5.75091 6.03942 5.63441 6.15979 5.546 6.3V6.301Z"
                fill="#F8F8F8"
            />
        </svg>
    );
};

export default MessengerSVG;
