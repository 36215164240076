const ImessageSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.45 49.48">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="67.81"
                    y1="556.18"
                    x2="67.62"
                    y2="507.3"
                    gradientTransform="matrix(1, 0, 0, -1, -40, 556.64)"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#58c5fa" />
                    <stop offset="1" stopColor="#0d83fd" />
                </linearGradient>
            </defs>
            <g id="Laag_2" data-name="Laag 2">
                <g id="Layer_1" data-name="Layer 1">
                    <g id="layer1">
                        <g id="g6206">
                            <path
                                id="path5903"
                                fill="url(#linear-gradient)"
                                d="M27.73,0C12.41,0,0,10.27,0,22.94,0,31,5.07,38.14,12.71,42.22l.19.23.14.24.11.24,0,.32v.37l0,.34-.12.4-.19.44-.24.39-.44.65-.63.85-.54.66-.7.72-.29.32-.27.35-.09.16V49l0,.13.08.17.1.1.07,0,.13,0h.2l.26,0,.36-.09.57-.15.77-.22,1-.34,1.19-.44.63-.28.84-.41,1-.59.75-.46.52-.37.46-.36.38-.25.29-.16.22-.09.27-.09.4-.12h0a33.3,33.3,0,0,0,7.56.86C43,45.87,55.45,35.6,55.45,22.94S43,0,27.73,0Z"
                            />
                            <path
                                id="path5925"
                                fill="none"
                                stroke="#000"
                                d="M13.49,40h0"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ImessageSVG;
