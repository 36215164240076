// React libraries
import { useState, useEffect } from 'react';

// User defined
import messageService from './services/messages';

// Third party
import _ from 'lodash';

// All SVG's for the buttons
import Liveblog from './components/Liveblog/Liveblog';
import Button from './components/Button/Button';
import { buttons } from './constants';

// Styles
import './Home.css';

const Home = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [messages, setMessages] = useState([]);
    const [locationStatus, setLocationStatus] = useState('idle');  // 'idle' | 'fetching' | 'error'

    useEffect(() => {
        const microSeconds =
            Number(process.env.REACT_APP_POLLING_INTERVAL_SECONDS) * 1000;

        const getMessages = () => {
            messageService
                .getAll()
                .then((response) => {
                    const responseMessages = response.data.filter(
                        (message) => message.show === true,
                    );

                    if (!_.isEqual(messages, responseMessages)) {
                        setMessages(responseMessages); // response.data is an array of objects
                    }
                    setIsLoaded(true);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                });
        };
        getMessages();

        const interval = setInterval(() => {
            getMessages();
        }, microSeconds);

        return () => clearInterval(interval);
    }, [messages]);

    useEffect(() => {
        console.log(locationStatus)
    }, [locationStatus])
    
    if (error) {
        return <div className="error-message">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
    } else if (locationStatus === "fetching") {
        return <div className="fetching">Please wait while we are fetching your location...</div>;
    } else {
        return (
            <>
                {messages.length > 0 && <Liveblog messages={messages} />}
                <div className="grid-container">
                    {buttons.map((button) => {
                        // If button is not 'show' do not display
                        if (!button.show) {
                            return null;
                        }
                        return (
                            <Button
                                key={button.id}
                                title={button.title}
                                titleShadow={button.titleShadow}
                                className={button.className}
                                textColorClass={button.textColorClass}
                                href={button.href}
                                contact={button.contact}
                                svg={button.svgComponent}
                                active={button.active}
                                setLocationStatus={setLocationStatus}
                            />
                        );
                    })}
                </div>
            </>
        );
    }
};

export default Home;
