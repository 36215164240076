import './like.css';

const Like = (props) => {
    return (
        <div className="like__block">
            <div className={props.className} onClick={props.likesHandler}></div>
            <div className="like__block-counter">{props.totalLikes}</div>
        </div>
    );
};

export default Like;
