const WhatsAppSVG = () => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_2)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.415 14.382C17.117 14.233 15.656 13.515 15.384 13.415C15.112
									13.316 14.914 13.267 14.715 13.565C14.517 13.861 13.948 14.531
									13.775 14.729C13.601 14.928 13.428 14.952 13.131 14.804C12.834
									14.654 11.876 14.341 10.741 13.329C9.858 12.541 9.261 11.568 9.088
									11.27C8.915 10.973 9.069 10.812 9.218 10.664C9.352 10.531 9.515
									10.317 9.664 10.144C9.813 9.97004 9.862 9.84604 9.961
									9.64704C10.061 9.44904 10.011 9.27604 9.936 9.12704C9.862 8.97804
									9.268 7.51504 9.02 6.92004C8.779 6.34104 8.534 6.42004 8.352
									6.41004C8.178 6.40204 7.98 6.40004 7.782 6.40004C7.584 6.40004
									7.262 6.47404 6.99 6.77204C6.717 7.06904 5.95 7.78804 5.95
									9.25104C5.95 10.713 7.014 12.126 7.163 12.325C7.312 12.523 9.258
									15.525 12.239 16.812C12.949 17.118 13.502 17.301 13.933
									17.437C14.645 17.664 15.293 17.632 15.805 17.555C16.375 17.47
									17.563 16.836 17.811 16.142C18.058 15.448 18.058 14.853 17.984
									14.729C17.91 14.605 17.712 14.531 17.414 14.382H17.415ZM11.993
									21.785H11.989C10.2184 21.7854 8.48037 21.3094 6.957 20.407L6.597
									20.193L2.855 21.175L3.854 17.527L3.619 17.153C2.62914 15.5774
									2.10529 13.7538 2.108 11.893C2.11 6.44304 6.544 2.00904 11.997
									2.00904C14.637 2.00904 17.119 3.03904 18.985 4.90704C19.9054
									5.82362 20.6349 6.91361 21.1313 8.11394C21.6277 9.31427 21.8811
									10.6011 21.877 11.9C21.875 17.35 17.441 21.785 11.993
									21.785V21.785ZM20.405 3.48804C19.3032 2.37896 17.9922 1.49958
									16.5481 0.900841C15.1039 0.302105 13.5553 -0.00407625 11.992
									4.09775e-05C5.438 4.09775e-05 0.102 5.33504 0.1 11.892C0.099
									13.988 0.646 16.034 1.687 17.837L0 24L6.304 22.346C8.04787 23.2962
									10.0021 23.794 11.988 23.794H11.993C18.547 23.794 23.883 18.459
									23.885 11.901C23.8898 10.3383 23.5848 8.79014 22.9874
									7.34607C22.3901 5.90201 21.5124 4.59071 20.405 3.48804"
                    fill="#F8F8F8"
                />
            </g>
            <defs>
                <clipPath id="clip0_8_2">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WhatsAppSVG;
