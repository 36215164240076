// User defined
import Logo from './components/Logo/Logo';
import Home from './Home';
import favicon from './assets/favicon.png';
import { Helmet } from 'react-helmet';

// Styles
import './App.css';
import fallBackPoster from './assets/fallback.jpg';

const backgroundVideo =
    process.env.REACT_APP_VIDEOBACKGROUND +
    '&background=1&autoplay=1&loop=1&byline=0&title=0';

const App = () => {
    return (
        <div className="main">
            <Helmet>
                <title> Livecrowd </title>
                <link rel="icon" type="image/x-icon" href={favicon}></link>
                <meta name="description" content="Liveblog"></meta>
            </Helmet>
            <div className="vimeo-wrapper">
                <iframe
                    src={backgroundVideo}
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    title='background'>
                </iframe>
                <img className="fallback-poster" src={fallBackPoster} alt="fallback"></img>
            </div>
            <div className="center_x">
                <Logo />
                <Home />
            </div>
        </div>
    );
};

export default App;
