// User defined
import { classNames } from '../../utils/classNames';
import { useState, useEffect, useMemo } from 'react';

// Styles
import './button.css';

const Button = (props) => {
    const [animate, setAnimate] = useState(false);
    const [sosHref, setSosHref] = useState(null);

    const token = '75e8276a-8492-4cfc-859d-3ff100d5fa40';
    const faqTags = useMemo(
        () => ({
            // add faq tag if exists
            tags: ['none'],
            filterType: 'article',
        }),
        []
    );

    const config = useMemo(
        () => ({
            content: {
                headers: {
                    chat: 'Livecrowd is here to help!',
                    chat_help: 'How can we help?',
                },
            },
            headerProperty: {
                hideChatButton: true,
            },
        }),
        []
    );

    useEffect(() => {
        let interval;
        if (props.className === 'btn--updates') {
            interval = setInterval(() => {
                setAnimate(true);
                setTimeout(() => setAnimate(false), 1000);
            }, 2500);
        }
        return () => clearInterval(interval);
    }, [props.className]);


    //freshchat
    useEffect(() => {
        (function () {
            if (typeof window !== 'undefined' && !window.fcWidget) {
                window.fcWidget = {
                    init: function (args) {
                        window.fcWidget.__args = args;
                    },
                };
                const script = document.createElement('script');
                script.src = 'https://wchat.freshchat.com/js/widget.js';
                script.async = true;
                document.head.appendChild(script);
            }
        })();

        window.fcWidget.init({
            token,
            host: 'https://wchat.freshchat.com',
            faqTags,
            config,
        });
    }, [token, faqTags, config]);

    const openWidget = () => {
        if (window.fcWidget) {
            window.fcWidget.open();
            window.fcWidget.show();
        }
    };

    if (props.title === "Live Chat") {
        return (
            <div
                onClick={openWidget}
                className="btn btn--livechat"
                target="_blank">
                <p className={classNames('small-text', props.textColorClass)}>
                    contact via
                </p>
                <p className={classNames('big-text', props.titleShadow)}>
                    {props.title}
                </p>
                <div className={classNames('svg-wrapper svg-livecrowd', animate ? 'animate' : '')}>
                    <props.svg />
                </div>
                <p
                    className={classNames(
                        'small-text',
                        props.textColorClass,
                        'contact-details',
                    )}>
                    {props.contact}
                </p>
            </div>
        )
    }

    // sos button
    const createSosHref = () => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser');
            return;
        }

        props.setLocationStatus('fetching');
        navigator.geolocation.getCurrentPosition(success, error);
    };

    const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const mapsSosUrl = `https://www.google.com/maps/?q=${latitude},${longitude}`;
        const sosHref = `https://api.whatsapp.com/send?phone=31638882552&text=Hey%20Livecrowd.%20Ik%20heb%20met%20spoed%20hulp%20nodig.%20Hier%20kun%20je%20mij%20vinden:%20${mapsSosUrl}`;
        setSosHref(sosHref);
        window.open(sosHref, '_self'); // opens the url in a new tab
        props.setLocationStatus('idle');
    };

    const error = () => {
        alert('Unable to retrieve your location, make sure you give the right permissions');
        props.setLocationStatus('error');
    };

    if (props.title === "S.O.S") {
        return (
            <>

                <a
                    onClick={createSosHref}
                    href={sosHref}
                    className="btn btn--sos"
                    target="_blank"
                    rel="noopener noreferrer">
                    <p className={classNames('small-text', props.textColorClass)}>
                        Ik heb met spoed hulp nodig
                    </p>
                    <p className={classNames('big-text', props.titleShadow)}>
                        {props.title}
                    </p>
                    <div className={classNames('svg-wrapper svg-livecrowd', animate ? 'animate' : '')}>
                        <props.svg />
                    </div>
                    <p
                        className={classNames(
                            'small-text',
                            props.textColorClass,
                            'contact-details',
                        )}>
                        {props.contact}
                    </p>
                </a>
            </>
        );
    }

    if (props.title === "X") {
        return (
            <a
                href={props.href}
                className={classNames(
                    'btn',
                    props.className,
                    !props.active ? 'btn--inactive' : '',
                )}
                target="_blank"
                rel="noreferrer">
                {props.className === 'btn--updates' && (
                    <p className={classNames('small-text', props.textColorClass)}>
                        Meld je aan voor
                    </p>
                )}
                {props.className === 'btn--maps' && (
                    <p className={classNames('small-text', props.textColorClass)}>
                        Locatie via
                    </p>
                )}
                {props.className !== 'btn--updates' && props.className !== 'btn--maps' && (
                    <p className={classNames('small-text', props.textColorClass)}>
                        Contact via
                    </p>
                )}
                <p className={classNames('big-text', props.titleShadow)}>
                    {props.title} <span className='normal-text'>(voorheen Twitter)</span>
                </p>
                <div className={classNames('svg-wrapper', animate ? 'animate' : '')}>
                    <props.svg />
                </div>
                <p
                    className={classNames(
                        'small-text',
                        props.textColorClass,
                        'contact-details',
                    )}>
                    {props.contact}
                </p>
            </a>
        );
    }
    //regular buttons
    return (
        <a
            href={props.href}
            className={classNames(
                'btn',
                props.className,
                !props.active ? 'btn--inactive' : '',
            )}
            target="_blank"
            rel="noreferrer">
            {props.className === 'btn--updates' && (
                <p className={classNames('small-text', props.textColorClass)}>
                    Meld je aan voor
                </p>
            )}
            {props.className === 'btn--maps' && (
                <p className={classNames('small-text', props.textColorClass)}>
                    Locatie via
                </p>
            )}
            {props.className !== 'btn--updates' && props.className !== 'btn--maps' && (
                <p className={classNames('small-text', props.textColorClass)}>
                    Contact via
                </p>
            )}
            <p className={classNames('big-text', props.titleShadow)}>
                {props.title}
            </p>
            <div className={classNames('svg-wrapper', animate ? 'animate' : '')}>
                <props.svg />
            </div>
            <p
                className={classNames(
                    'small-text',
                    props.textColorClass,
                    'contact-details',
                )}>
                {props.contact}
            </p>
        </a>
    );
};

export default Button;
