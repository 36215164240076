import './liveblog.css';
import { classNames } from '../../utils/classNames';
import { formatDate } from '../../utils/formatDate';
import Interactions from './Interactions';
import ParsedMessage from '../ParsedMessage';
import { useEffect, useState } from 'react';

const Post = ({ message }) => {
    const messageDatetime = formatDate(message['updated_at']);
    const messageDate = messageDatetime[0];
    const messageTime = messageDatetime[1];

    return (
        <>
            <div className="timeline__left">
                <div className="timeline__left_date">
                    {messageDate} - {messageTime}
                </div>
            </div>

            <div className="timeline__middle">
                <div
                    className={classNames(
                        'timeline__point',
                        message['alert']
                            ? 'timeline__point--red'
                            : 'timeline__point--grey',
                    )}></div>
            </div>

            <div className="timeline__content fadein">
                <div className="timeline__right_date">
                    {messageDate} - {messageTime}
                </div>
                <h2>{message['title']}</h2>
                <div className="timeline__content_message">
                    <ParsedMessage message={message} />
                    <Interactions message={message} />
                </div>
            </div>
        </>
    );
};

const Liveblog = ({ messages }) => {
    const lastMessage = messages[messages.length - 1];
    const messageDatetime = formatDate(lastMessage['updated_at']);
    const messageDate = messageDatetime[0];
    const messageTime = messageDatetime[1];
    const [messageList, setMessageList] = useState(messages.slice(0, 3));
    const [expand, setExpand] = useState(false);
    const [expandMessage, setExpandMessage] = useState('Meer lezen');
    const messagesLength = messages.length;

    useEffect(() => {
        if (expand === false) {
            setMessageList(messages.slice(0, 3));
            setExpandMessage('Meer lezen');
        } else if (expand === true) {
            setMessageList(messages);
            setExpandMessage('Minder lezen');
        }
    }, [expand, messages]);

    const handleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div>
            <div className="liveblog-container">
                <div className="liveblog">
                    <div className="topbar">
                        <div className="live_pulsating_text">LIVE</div>
                        <div className="last_update">
                            Last update: {messageDate} - {messageTime}
                        </div>
                    </div>

                    <div className="timeline">
                        {messageList.map((message) => {
                            return (
                                <Post key={message['id']} message={message} />
                            );
                        })}
                    </div>
                    {messagesLength > 3 && (
                        <div className="button-container">
                            <a
                                href="#/"
                                className="expand-btn"
                                onClick={handleExpand}>
                                {expandMessage}
                            </a>
                        </div>
                    )}
                </div>
                <div className="footer-bar"></div>
            </div>
        </div>
    );
};

export default Liveblog;
