const LivecrowdSVG = () => {
    return (
        <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 283.5 283.5"  >
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="141.7324" y1="236.6577" x2="141.7324" y2="46.8061">
                <stop offset="0" />
                <stop offset="1" />
            </linearGradient>
            <path className="st0" fill="white" d="M143.9,165.2L143.9,165.2c0,4.1-2.9,7.4-7.1,7.4c-4.2,0-7.2-3.4-7.2-7.4v-0.1c0-4.1,2.9-7.4,7.1-7.4
	C141,157.8,143.9,161.1,143.9,165.2z M108.8,158.1h-5.2v6.8h5.3c2.5,0,4-1.3,4-3.3v-0.1C112.8,159.2,111.3,158.1,108.8,158.1z
	 M205.2,158.1h-4v14.3h4c4.3,0,7.1-2.9,7.1-7.1v-0.1C212.3,161,209.4,158.1,205.2,158.1z M196.8,215.1c-1.8,0.7-3.4,1.3-5,1.9
	c-18.7,7.3-58.9,18.6-79.2,19.6c-0.5,0-1.1,0-1.6,0c0,0,0,0,0,0c-10.7,0-27.6-4.6-37.6-13.2c-10-8.5-14.7-15.1-18-25.3
	c-6.6-20.4-13.4-85.5-13.4-86.2c0-0.2-1.9-15.5,1-31.2C46.5,60.3,56.1,49,71.1,47.2c2-0.2,4.1-0.4,6.3-0.4c39.5,0,91.7,38.6,97.5,43
	c19.6,14.8,65.4,51.8,67.1,75.7C244.4,197.2,215.7,207.9,196.8,215.1z M136.5,140.7h18v-4.6h-12.8v-5h11.1v-4.6h-11.1v-4.8h12.7
	v-4.6h-17.8V140.7z M71.6,140.7h17V136H76.8v-18.9h-5.2V140.7z M91.7,173.2l-3.3-3.3c-1.9,1.7-3.5,2.8-6.2,2.8
	c-4.1,0-6.9-3.4-6.9-7.4v-0.1c0-4.1,2.9-7.4,6.9-7.4c2.4,0,4.2,1,6,2.7l3.3-3.8c-2.2-2.2-4.9-3.6-9.3-3.6c-7.3,0-12.3,5.5-12.3,12.2
	v0.1c0,6.8,5.2,12.2,12.1,12.2C86.6,177.4,89.3,175.8,91.7,173.2z M95.1,140.7h5.2v-23.6h-5.2V140.7z M118.8,177l-5.8-8.4
	c3-1.1,5.1-3.5,5.1-7.3v-0.1c0-4.9-3.3-7.8-8.9-7.8H98.4V177h5.2v-7.6h4.1l5.1,7.6H118.8z M120.7,140.9l9.6-23.8h-5.6l-6.2,16.7
	l-6.2-16.7h-5.7l9.6,23.8H120.7z M149.4,165.1c0-6.7-5.2-12.2-12.5-12.2c-7.3,0-12.6,5.5-12.6,12.2v0.1c0,6.7,5.2,12.2,12.5,12.2
	C144.1,177.4,149.4,171.9,149.4,165.1L149.4,165.1z M189.6,153.4h-5.4l-4.9,16l-5.3-16.1h-4.5l-5.3,16.1l-4.9-16h-5.6l8.1,23.8h4.5
	l5.3-15.5l5.3,15.5h4.5L189.6,153.4z M217.7,165.1c0-6.7-5.1-11.8-12.6-11.8h-9.2V177h9.2C212.6,177,217.7,171.8,217.7,165.1
	L217.7,165.1z"/>
        </svg>
    );
};

export default LivecrowdSVG;
