import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const eventName = process.env.REACT_APP_EVENT_NAME;

const getAll = () => {
    return axios.get(`${baseUrl}/messages/${eventName}/`);
};

const update = (newObject, id) => {
    return axios.put(`${baseUrl}/messages/update/${id}/`, newObject);
};

const like = (id, clicked) => {
    return axios.put(`${baseUrl}/messages/like/${id}/`, { clicked: clicked });
};

const dislike = (id, clicked) => {
    return axios.put(`${baseUrl}/messages/dislike/${id}/`, {
        clicked: clicked,
    });
};

const exportData = {
    getAll,
    update,
    like,
    dislike,
};

export default exportData;
